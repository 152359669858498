import type { NextPage } from 'next';
import Head from 'next/head';
import NextLink from 'next/link';
import { Box, Grid, Typography } from '@mui/material';
import { GuestGuard } from '@/components/authentication/guest-guard';
import LoginImage from '@/assets/images/screenshots/login-image.png';
import Image from 'next/image';
import { Logo } from '@/assets/logos/logo';
import { RtkLogin } from '@/components/authentication/rtk-login';
import CopyrightTerms from '@/components/_shared/copyright-terms';
import { useRouter } from 'next/router';
import { EnvelopeIcon } from '@heroicons/react/20/solid';
import AiPoweredInsights from '@/assets/images/screenshots/ai-powered-insights.png';
const Login: NextPage = () => {
  const router = useRouter();
  const isResetSent = router.query.origin === 'reset-sent';
  return <>
      <Head data-sentry-element="Head" data-sentry-source-file="login.tsx">
        <title>Login | RipeMetrics</title>
      </Head>
      <Box component="main" sx={{
      display: 'flex',
      flex: '1 1 auto'
    }} data-sentry-element="Box" data-sentry-source-file="login.tsx">
        <Grid container sx={{
        flex: '1 1 auto'
      }} data-sentry-element="Grid" data-sentry-source-file="login.tsx">
          <Grid item xs={12} lg={6} sx={{
          backgroundColor: 'neutral.50',
          display: 'flex',
          flexDirection: 'column',
          position: 'relative'
        }} data-sentry-element="Grid" data-sentry-source-file="login.tsx">
            <Box sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            p: 3
          }} data-sentry-element="Box" data-sentry-source-file="login.tsx">
              <NextLink href="https://www.ripemetrics.com/" passHref data-sentry-element="NextLink" data-sentry-source-file="login.tsx">
                <Logo data-sentry-element="Logo" data-sentry-source-file="login.tsx" />
              </NextLink>
            </Box>
            <Box sx={{
            flex: '1 1 auto',
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center'
          }} data-sentry-element="Box" data-sentry-source-file="login.tsx">
              <Box sx={{
              maxWidth: 500,
              px: 3,
              py: '100px',
              width: '100%'
            }} data-sentry-element="Box" data-sentry-source-file="login.tsx">
                <div>
                  <Typography sx={{
                  mb: 1
                }} variant="h4" data-sentry-element="Typography" data-sentry-source-file="login.tsx">
                    {isResetSent ? 'Forgot your password?' : 'Welcome to RipeMetrics'}
                  </Typography>
                  {isResetSent ? <div className="p-4 my-4 border-l-4 border-yellow-400 bg-yellow-50">
                      <div className="flex">
                        <div className="shrink-0">
                          <EnvelopeIcon aria-hidden="true" className="w-5 h-5 text-yellow-400" />
                        </div>
                        <div className="ml-3">
                          <p className="text-sm text-yellow-700">
                            Check your email for a password reset link.
                          </p>
                        </div>
                      </div>
                    </div> : <Typography color="text.secondary" sx={{
                  mb: 3
                }} variant="body2">
                      The AI-native customer growth platform for e-commerce
                      businesses.
                    </Typography>}
                  <RtkLogin data-sentry-element="RtkLogin" data-sentry-source-file="login.tsx" />
                </div>
              </Box>
            </Box>
            <div className="my-4">
              <CopyrightTerms data-sentry-element="CopyrightTerms" data-sentry-source-file="login.tsx" />
            </div>
          </Grid>
          <Grid item xs={12} lg={6} sx={{
          alignItems: 'center',
          background: 'radial-gradient(50% 50% at 50% 50%, #eab308 0%, #FF792A 100%)',
          color: 'white',
          display: 'flex',
          justifyContent: 'center',
          '& img': {
            maxWidth: '100%'
          }
        }} data-sentry-element="Grid" data-sentry-source-file="login.tsx">
            <div className="flex flex-col items-center justify-center p-3 space-y-6">
              <Typography align="center" color="inherit" sx={{
              fontSize: '16px',
              lineHeight: '32px',
              mb: 1
            }} data-sentry-element="Typography" data-sentry-source-file="login.tsx">
                Not using RipeMetrics yet?&nbsp;&nbsp;
              </Typography>
              <h2 className="text-4xl tracking-tight text-center text-white font-proximaSemiBold sm:text-4xl">
                <span className="block">
                  Grow Your E-Commerce Business with AI
                </span>
              </h2>
              <Image priority alt="RipeMetrics AI-Native Customer Growth Platform" src={AiPoweredInsights} sizes="35.75rem" className="w-[42rem] rounded-md shadow-2xl ring-1 ring-gray-900/10" data-sentry-element="Image" data-sentry-source-file="login.tsx" />
              {/* <Image
                alt=""
                src={LoginImage}
               /> */}
              <div className="max-w-2xl pb-6 mx-auto text-center sm:px-6 lg:px-8">
                <h2 className="text-2xl font-semibold text-center text-white">
                  All-in-One Customer Growth Platform
                </h2>
                <div className="mt-4 text-lg leading-6 text-orange-50">
                  Log in to experience the AI-powered platform that helps you
                  understand customers, create personalized marketing campaigns,
                  and drive growth for your e-commerce business.
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </Box>
    </>;
};
Login.getLayout = page => <GuestGuard>{page}</GuestGuard>;
export default Login;